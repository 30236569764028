<template>
    <img
        :src="imageUrl"
        :class="pictureClass"
        loading="lazy"
        decoding="async"
        :alt="alt">
</template>

<script>

export default {
    props:
        {
            convertImage: {
                type: Boolean,
                default: true
            },
            imageUrl: {
                type: String,
                default: null
            },
            fallbackUrl: {
                type: String,
                default: null
            },
            isBackgroundImage: {
                type: Boolean,
                default: false
            },
            pictureClass: {
                type: String,
                default: null
            },
            alt: {
                type: String,
                default: null
            },
            title: {
                type: String,
                default: null
            }
        }
}
</script>

<template>
    <div v-if="isLoggedIn">
        <a :href="$ceres.urls.myAccount" class="nav-link text-primary" title="$translate('Ceres::Template.loginMyAccount')">
            <svg fill="none" height="40" viewBox="0 0 24 24" width="40" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="m12 12c2.7614 0 5-2.23858 5-5s-2.2386-5-5-5c-2.76142 0-5 2.23858-5 5s2.23858 5 5 5z"></path><path d="m20.5901 22c0-3.87-3.8499-7-8.5899-7-4.74005 0-8.59004 3.13-8.59004 7" opacity=".4"></path></g></svg>
        </a>
    </div>
    <div v-else>
        <a v-if="showLogin" data-testing="login-select" :href="isLogin ? 'javascript:void(0)' : '#login'" :data-toggle="isLogin ? false : 'modal'" @click="createLoginModal(); unmarkInputFields();" :aria-label="$translate('Ceres::Template.login')" class="nav-link text-primary">
            <svg fill="none" height="40" viewBox="0 0 24 24" width="40" xmlns="http://www.w3.org/2000/svg"><g stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="m12 12c2.7614 0 5-2.23858 5-5s-2.2386-5-5-5c-2.76142 0-5 2.23858-5 5s2.23858 5 5 5z"></path><path d="m20.5901 22c0-3.87-3.8499-7-8.5899-7-4.74005 0-8.59004 3.13-8.59004 7" opacity=".4"></path></g></svg>
        </a>
        <template v-if="showRegistration">
            <span class="pipe" v-if="!showLogin"></span>
            <a class="nav-link" data-testing="register-select" :href="isRegister ? 'javascript:void(0)' : '#registration'" :data-toggle="isRegister ? false : 'modal'"  @click="createRegisterModal(); unmarkInputFields();" :aria-label="$translate('Ceres::Template.loginRegister')">
                <i class="fa fa-user-plus" aria-hidden="true"></i>
                <span class="d-none d-sm-inline d-md-none d-lg-inline ml-1">{{ $translate("Ceres::Template.loginRegister") }}</span>
            </a>
        </template>
    </div>
</template>

<script>
import ValidationService from "../../../services/ValidationService";
import { mapGetters, mapActions } from "vuex";

export default {
    props: {
        showRegistration: {
            type: Boolean,
            default: true
        },
        showLogin: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        ...mapGetters([
           "username",
           "isLoggedIn"
        ])
    },

    data()
    {
        return {
            isLogin: App.templateType === "login",
            isRegister: App.templateType === "register"
        };
    },

    methods: {
        unmarkInputFields()
        {
            ValidationService.unmarkAllFields($("#login"));
            ValidationService.unmarkAllFields($("#registration"));
        },

        createLoginModal()
        {
            this.loadComponent("login-modal");
        },

        createRegisterModal()
        {
            this.loadComponent("register-modal");
        },

        ...mapActions([
            "loadComponent"
        ])
    }
}
</script>

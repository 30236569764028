var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoggedIn
    ? _c("div", [
        _c(
          "a",
          {
            staticClass: "nav-link text-primary",
            attrs: {
              href: _vm.$ceres.urls.myAccount,
              title: "$translate('Ceres::Template.loginMyAccount')",
            },
          },
          [
            _c(
              "svg",
              {
                attrs: {
                  fill: "none",
                  height: "40",
                  viewBox: "0 0 24 24",
                  width: "40",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c(
                  "g",
                  {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "1.5",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "m12 12c2.7614 0 5-2.23858 5-5s-2.2386-5-5-5c-2.76142 0-5 2.23858-5 5s2.23858 5 5 5z",
                      },
                    }),
                    _c("path", {
                      attrs: {
                        d: "m20.5901 22c0-3.87-3.8499-7-8.5899-7-4.74005 0-8.59004 3.13-8.59004 7",
                        opacity: ".4",
                      },
                    }),
                  ]
                ),
              ]
            ),
          ]
        ),
      ])
    : _c(
        "div",
        [
          _vm.showLogin
            ? _c(
                "a",
                {
                  staticClass: "nav-link text-primary",
                  attrs: {
                    "data-testing": "login-select",
                    href: _vm.isLogin ? "javascript:void(0)" : "#login",
                    "data-toggle": _vm.isLogin ? false : "modal",
                    "aria-label": _vm.$translate("Ceres::Template.login"),
                  },
                  on: {
                    click: function ($event) {
                      _vm.createLoginModal()
                      _vm.unmarkInputFields()
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        fill: "none",
                        height: "40",
                        viewBox: "0 0 24 24",
                        width: "40",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "1.5",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "m12 12c2.7614 0 5-2.23858 5-5s-2.2386-5-5-5c-2.76142 0-5 2.23858-5 5s2.23858 5 5 5z",
                            },
                          }),
                          _c("path", {
                            attrs: {
                              d: "m20.5901 22c0-3.87-3.8499-7-8.5899-7-4.74005 0-8.59004 3.13-8.59004 7",
                              opacity: ".4",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showRegistration
            ? [
                !_vm.showLogin ? _c("span", { staticClass: "pipe" }) : _vm._e(),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: {
                      "data-testing": "register-select",
                      href: _vm.isRegister
                        ? "javascript:void(0)"
                        : "#registration",
                      "data-toggle": _vm.isRegister ? false : "modal",
                      "aria-label": _vm.$translate(
                        "Ceres::Template.loginRegister"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        _vm.createRegisterModal()
                        _vm.unmarkInputFields()
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-user-plus",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass:
                          "d-none d-sm-inline d-md-none d-lg-inline ml-1",
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$translate("Ceres::Template.loginRegister")
                          )
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("img", {
    class: _vm.pictureClass,
    attrs: {
      src: _vm.imageUrl,
      loading: "lazy",
      decoding: "async",
      alt: _vm.alt,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: { "p-0": _vm.$ceres.isShopBuilder } },
    [
      _c(
        "label",
        { staticClass: "sr-only", attrs: { for: "item-search_" + _vm._uid } },
        [
          _vm._v(
            _vm._s(
              _vm.$translate(
                "PuresportsTheme::Template.headerSearchBarPlaceholder"
              )
            )
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "input-group input-group-lg" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchString,
              expression: "searchString",
            },
          ],
          ref: "searchInput",
          staticClass: "form-control border-primary",
          attrs: {
            id: "item-search_" + _vm._uid,
            type: "search",
            placeholder: _vm.$translate(
              "PuresportsTheme::Template.headerSearchBarPlaceholder"
            ),
            autofocus: _vm.isShopBuilder,
            "aria-label": _vm.$translate("Ceres::Template.headerSearchTerm"),
          },
          domProps: { value: _vm.searchString },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.searchString = $event.target.value
              },
              function ($event) {
                return _vm.onValueChanged($event.target.value)
              },
            ],
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.search()
            },
            focus: function ($event) {
              _vm.isSearchFocused = true
            },
            blur: function ($event) {
              return _vm.onBlurSearchField($event)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "input-group-append" },
          [
            _vm._t("search-button", function () {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-primary py-0 px-3",
                    attrs: {
                      type: "submit",
                      "aria-label": _vm.$translate(
                        "Ceres::Template.headerSearch"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.search()
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$translate(
                            "PuresportsTheme::Template.headerSearchButtonName"
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "svg",
                      {
                        attrs: {
                          fill: "none",
                          height: "32",
                          viewBox: "0 0 24 24",
                          width: "32",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              "stroke-linecap": "round",
                              "stroke-linejoin": "round",
                              "stroke-width": "1.5",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "m11.5 21c5.2467 0 9.5-4.2533 9.5-9.5 0-5.24671-4.2533-9.5-9.5-9.5-5.24671 0-9.5 4.25329-9.5 9.5 0 5.2467 4.25329 9.5 9.5 9.5z",
                              },
                            }),
                            _c("path", {
                              attrs: { d: "m22 22-2-2", opacity: ".4" },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.isSearchFocused
        ? [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      (_vm.searchString.length >= _vm.searchMinLength &&
                        _vm.hasInitialInput) ||
                      _vm.$ceres.isShopBuilder,
                    expression:
                      "(searchString.length >= searchMinLength && hasInitialInput) || $ceres.isShopBuilder",
                  },
                ],
              },
              [
                _vm._t("autocomplete-suggestions", function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "autocomplete-suggestions shadow bg-white w-100",
                      },
                      [
                        _c("search-suggestion-item", {
                          attrs: {
                            "show-images": _vm.showItemImages,
                            "suggestion-type": "item",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }